import { Component, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';
import Layout from '@/components/layouts/auth.vue';
import { UserSignUpPhoneCodeRequest } from '@/types/request/auth-request.types';
import { ValidationObserver } from 'vee-validate';
import BTextInputValidation from '@/components/ui/inputs/b-text-input-validation/bTextInputValidation.vue';

const title = 'login.title';

@Component({
  page: {
    title
  },
  components: {
    Layout,
    ValidationObserver,
    BTextInputValidation
  },
})
export default class Login extends Vue {
  login = '';
  password = '';
  tryingToLogIn = false;

  @Action('signUp', { namespace: 'auth' })
  signUp!: (params: UserSignUpPhoneCodeRequest) => Promise<string>;

  tryToLogIn() {
    if (!this.tryingToLogIn) {
      this.tryingToLogIn = true;

      return this.signUp({ login: this.login, password: this.password })
        .then(() => {
          this.tryingToLogIn = false;
          this.$router.push({ name: 'home' });
        })
        .catch(() => {
          this.tryingToLogIn = false;
        });
    }
  }
}
